import React, { useState } from 'react';
import { Card, Image, Row, Col, Drawer, Tag, Button, Divider, Modal, Flex, Input, Form, Rate } from 'antd';
import burgerImage from './../assets/images/burger.png';
import pizzaImage from './../assets/images/pizza.png';
import drinkImage from './../assets/images/drink.png';
import { BarChartOutlined, ClockCircleOutlined, CommentOutlined, EnvironmentOutlined, FireOutlined, HeartOutlined, HomeOutlined, LeftOutlined, PlusOutlined, RollbackOutlined, ShareAltOutlined, ShoppingCartOutlined, ShoppingOutlined, WifiOutlined } from '@ant-design/icons';
import { Fonksiyon } from './ToyaFunction';
import CounterComponent from './CounterComponent';
import Icon from '@ant-design/icons/lib/components/Icon';
import FixedButtonGroup from './FixedButtonGroup';
import WifiModal from './WifiModal';
import HaritaAc from './HaritaAc';

const Categories = () => {
    const [products, setProducts] = useState([]);
    const [menu, setMenu] = useState({});
    const [product, setProduct] = useState({})
    const [yetki, setYetki] = useState(
        {
            sepeteEklensinmi: true,
            sepetEkrani: true,
        }
    );

    const [degerlendirmeModalVisible, setDegerlendirmeModalVisible] = useState(false)

    const [wifiVisible, setWifiVisible] = useState(false)

    const [sepetEkleModalVisible, setSepetEkleModalVisible] = useState(false);
    const [productPrice, setProductPrice] = useState(100); // Örnek bir başlangıç fiyatı
    const [wifiPass, setWifiPass] = useState("Categories Page")
    const menuItems = [
        { id: 1, name: 'Pizza', imageUrl: pizzaImage },
        { id: 2, name: 'Burger', imageUrl: burgerImage },
        { id: 3, name: 'İçecek', imageUrl: drinkImage },

    ];
    const productItems = [
        { menuId: 1, id: 1, name: "Peynirli Pizza", price: 250, description: "İçinde peynir olan pizza", time: 20, calori: 250, imageUrl: pizzaImage },
        { menuId: 1, id: 2, name: "Zeytinli Pizza", price: 240, description: "İçinde zeytin olan pizza", time: 17, calori: 150, imageUrl: pizzaImage },
        { menuId: 1, id: 3, name: "Sucuklu Pizza", price: 270, description: "İçinde sucuk olan pizza", time: 22, calori: 350, imageUrl: pizzaImage },
        { menuId: 2, id: 4, name: "Tavuklu Burger", price: 220, description: "İçinde tavuk olan burger", time: 15, calori: 100, imageUrl: burgerImage },
        { menuId: 2, id: 5, name: "Salatalı Burger", price: 200, description: "İçinde salata olan burger", time: 13, calori: 80, imageUrl: burgerImage },
        { menuId: 2, id: 6, name: "Patatesli Burger", price: 210, description: "İçinde patates olan burger", time: 17, calori: 120, imageUrl: burgerImage },
        { menuId: 3, id: 7, name: "Gazlı İçecek", price: 50, description: "", time: 0, calori: 120, imageUrl: drinkImage },
        { menuId: 3, id: 8, name: "Soda", price: 30, description: "", time: 0, calori: 170, imageUrl: drinkImage },
        { menuId: 3, id: 9, name: "Ayran", price: 40, description: "", time: 0, calori: 220, imageUrl: drinkImage }
    ];
    const [open, setOpen] = useState(false);


    const handlePriceChange = (newPrice) => {
        setProductPrice(newPrice);
    };





    const showDrawer = (item) => {
        let _product = productItems.filter(x => x.menuId === item.id);
        setProducts(_product);
        setMenu(item);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const ProductsCards = () => {
        return (
            <Row gutter={[16, 16]} style={{ paddingBottom: '6rem' }} >
                {menuItems.map(item => (
                    <Col xs={24} sm={12} md={8} lg={6} key={item.id}>
                        <Card
                            onClick={() => showDrawer(item)}
                            bordered={false}
                            style={{
                                marginBottom: '1rem',
                                textAlign: 'center',
                                maxHeight: '100%',
                                overflow: 'hidden',
                            }}
                            cover={
                                <Image
                                    src={item.imageUrl}
                                    preview={false}
                                    style={{
                                        maxHeight: '300px',
                                        maxWidth: '300px',
                                        padding: 0,
                                        margin: 0
                                    }}
                                />
                            }
                            title={item.name}
                        />
                    </Col>
                ))}
            </Row>
        );
    };

    const SepeteEkle = (item) => {
        setProduct(item)
        SepeteModalAc();
    };
    const SepeteModalAc = () => {
        setOpen(false);
        setSepetEkleModalVisible(true);
    };
    const SepeteModalKapat = () => {
        setSepetEkleModalVisible(false);
        setOpen(true)
    };
    const SepeteEkleModal = () => {
        const [count, setCount] = useState(1);
        const [totalPrice, setTotalPrice] = useState(product.price);

        const handleCountChange = (newCount) => {
            setCount(newCount);
            setTotalPrice(newCount * product.price);
        };

        return (
            <Modal
                title={<Image
                    src={product.imageUrl}
                    preview={false}
                    style={{
                        objectFit: 'cover',
                        padding: 0,
                        margin: 0,
                    }}
                />}
                open={sepetEkleModalVisible}
                onOk={SepeteModalKapat}
                onCancel={SepeteModalKapat}
                zIndex={2000}
                footer={null}
                style={{
                    top: 0,
                    bottom: 0,
                    height: "97vh",
                    overflowY: "auto",
                    borderRadius: "0.5rem",
                }}
            >
                <div>
                    <div style={{ flex: 1 }}>
                        <h3 style={{ margin: 0 }}>{product.name}</h3>
                        <p style={{ margin: '0.5rem 0' }}>{product.description}</p>

                        <Tag color="volcano" style={{ width: "100%", textAlign: "center", marginBottom: "0.5rem" }}>
                            <FireOutlined /> Besin Değeri: {product.calori} kcal
                        </Tag>
                        {product.time === 0
                            ?
                            null
                            :
                            <Tag color="cyan" style={{ width: "100%", textAlign: "center", marginBottom: "0.5rem" }}>
                                <ClockCircleOutlined /> Pişirme Süresi: {product.time} dakika
                            </Tag>
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1 style={{ alignSelf: 'flex-start' }}>{Fonksiyon.EkranIcinParaFormatla(totalPrice)} ₺</h1>
                        <CounterComponent count={count} onCountChange={handleCountChange} style={{ alignSelf: 'flex-end' }} />
                    </div>

                    <Button type='primary' danger style={{ width: "100%" }}>
                        <h2>
                            Sepete Ekle
                        </h2>
                    </Button>
                </div>
            </Modal >
        );
    };



    const DegerlendirmeModal = () => {
        return (
            <Modal
                open={degerlendirmeModalVisible}
                onOk={() => setDegerlendirmeModalVisible(false)}
                onCancel={() => setDegerlendirmeModalVisible(false)}
                zIndex={2000}
                footer={null}
            >
                <div style={{ flex: 1 }}>
                    <h3 style={{ margin: 0 }}>Değerlendirme</h3>
                    <p style={{ margin: '0.5rem 0' }}>Bu restoranın değerlendirmesi için yorum yapabilirsiniz.</p>
                    <Input.TextArea rows={4} />
                    <Button type='primary' danger style={{ width: "100%" }}>
                        <h2>
                            Değerlendir
                        </h2>
                    </Button>
                </div>
            </Modal >
        );
    }
    const RatingModal = () => {
        const [isModalVisible, setIsModalVisible] = useState(false);
        const [ratings, setRatings] = useState({
            food: 0,
            service: 0,
            price: 0,
        });

        const showModal = () => {
            setIsModalVisible(true);
        };

        const handleOk = () => {
            console.log('Ratings:', ratings);
            setIsModalVisible(false);
        };

        const handleCancel = () => {
            setIsModalVisible(false);
        };

        const handleRateChange = (key, value) => {
            setRatings(prevRatings => ({
                ...prevRatings,
                [key]: value,
            }));
        };

        return (
            <>
                <Button type="primary" onClick={showModal}>
                    Değerlendirme Yap
                </Button>
                <Modal
                    title="Değerlendirme Yap"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <Form layout="vertical">
                        <Form.Item label="Yemek">
                            <Rate onChange={value => handleRateChange('food', value)} value={ratings.food} />
                        </Form.Item>
                        <Form.Item label="Hizmet">
                            <Rate onChange={value => handleRateChange('service', value)} value={ratings.service} />
                        </Form.Item>
                        <Form.Item label="Fiyat">
                            <Rate onChange={value => handleRateChange('price', value)} value={ratings.price} />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    };

    const CustomDrawer = () => {
        return (
            <Drawer
                title={menu.name}
                onClose={onClose}
                open={open}
                size={"large"}
                closeIcon={<LeftOutlined />}
                zIndex={1000} // Adjust the z-index here
            >
                {
                    products.map((m) => (
                        <div key={m.id}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <Image
                                    src={m.imageUrl}
                                    preview={false}
                                    style={{
                                        maxHeight: '100px',
                                        maxWidth: '100px',
                                        objectFit: 'cover',
                                        padding: 0,
                                        margin: 0
                                    }}
                                />
                                <div style={{ marginLeft: '1rem', flex: 1 }}>
                                    <h3 style={{ margin: 0 }}>{m.name}</h3>
                                    <p style={{ margin: '0.5rem 0' }}>{Fonksiyon.EkranIcinParaFormatla(m.price)} ₺</p>
                                </div>
                                {yetki.sepeteEklensinmi ?
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: '1rem' }}
                                        onClick={() => { SepeteEkle(m) }}
                                    />
                                    :
                                    <div></div>
                                }
                            </div>
                            <p style={{ margin: 0 }}>{m.description}</p>
                            <div style={{ marginTop: '0.5rem' }}>
                                <Tag color="volcano">
                                    <FireOutlined /> {m.calori} kcal
                                </Tag>
                                {
                                    m.time === 0
                                        ?
                                        null
                                        :
                                        <Tag color="cyan" style={{ marginLeft: '0.5rem' }}>
                                            <ClockCircleOutlined /> {m.time} dakika
                                        </Tag>
                                }
                            </div>
                            <Divider />
                        </div>
                    ))
                }
            </Drawer>
        );
    };


    return (
        <div style={{ padding: '2rem', maxHeight: '100vh', overflowY: 'auto' }}>

            <ProductsCards />
            <CustomDrawer />
            <SepeteEkleModal />
            <WifiModal wifiVisible={wifiVisible} setWifiVisible={setWifiVisible} wifiPass={wifiPass} />
            <RatingModal />
            <FixedButtonGroup HaritaAc={HaritaAc} setWifiVisible={setWifiVisible} yetki={yetki} />
        </div>
    );
};

export default Categories;

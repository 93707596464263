import Products from './pages/Products';
import Categories from './pages/Categories';
import ShoppingCart from './pages/ShoppingCart';

const publicRoutes = [
    { name: "ShoppingCart", path: "/shopping-cart", element: <ShoppingCart /> },
    { name: "Categories", path: "/", element: <Categories /> },
    { name: "Categories", path: "/*", element: <Categories /> },
    { name: "Categories", path: "/*", element: <Categories /> },
]

export { publicRoutes } 

import React, { useState } from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';


const CounterComponent = ({ count, onCountChange }) => {
    const decreaseCount = () => {
        if (count > 1) {
            onCountChange(count - 1);
        }
    };

    const increaseCount = () => {
        onCountChange(count + 1);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
                type="primary"
                danger
                icon={<MinusOutlined />}
                onClick={decreaseCount}
                style={{ marginRight: '1rem' }}
                disabled={count === 1}
            />
            <div style={{ minWidth: '3rem', textAlign: 'center', fontSize: '1rem', fontWeight: 'bold' }}>
                {count}
            </div>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={increaseCount}
                style={{ marginLeft: '1rem' }}
            />
        </div>
    );
};

export default CounterComponent;


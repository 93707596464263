import React, { useState } from 'react';
import FixedButtonGroup from './FixedButtonGroup';
import WifiModal from './WifiModal';
import HaritaAc from './HaritaAc';
import { useNavigate } from 'react-router-dom';


const ShoppingCart = () => {
    const [wifiVisible, setWifiVisible] = useState(false)
    const [wifiPass, setWifiPass] = useState("ShoppingCart Page")
    const [yetki, setYetki] = useState(
        {
            sepeteEklensinmi: true,
            sepetEkrani: false,
        }
    );
    const navigate = useNavigate();



    return (
        <>
            ada
            <FixedButtonGroup HaritaAc={HaritaAc} setWifiVisible={setWifiVisible} yetki={yetki} />
            <WifiModal wifiVisible={wifiVisible} setWifiVisible={setWifiVisible} wifiPass={wifiPass} />
        </>
    );
};

export default ShoppingCart;

import { CommentOutlined, EnvironmentOutlined, HomeOutlined, ShareAltOutlined, ShoppingOutlined, WifiOutlined } from '@ant-design/icons';
import { Divider, Flex } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function FixedButtonGroup({ setWifiVisible, HaritaAc, yetki }) {
    const navigate = useNavigate()
    return (
        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#2196f3', padding: '1rem' }}>

            <Flex style={{ width: '100%' }} justify={"space-around"} align={"center"}   >

                <WifiOutlined style={{ color: "white", fontSize: "150%" }} onClick={() => setWifiVisible(true)} />
                <Divider type="vertical" style={{ backgroundColor: 'white', fontSize: "170%" }} /> {/* Vertical line */}
                <EnvironmentOutlined style={{ color: "white", fontSize: "150%" }} onClick={HaritaAc} />

                {
                    yetki.sepeteEklensinmi ?
                        <>
                            <Divider type="vertical" style={{ backgroundColor: 'white', fontSize: "170%" }} /> {/* Vertical line */}
                            {
                                yetki.sepetEkrani ?
                                    <ShoppingOutlined style={{ color: "white", fontSize: "150%" }} onClick={() => navigate("/shopping-cart")} /> :
                                    <HomeOutlined style={{ color: "white", fontSize: "150%" }} onClick={() => navigate("/")} />
                            }
                        </>
                        :
                        <></>
                }
                <Divider type="vertical" style={{ backgroundColor: 'white', fontSize: "170%" }} /> {/* Vertical line */}
                <CommentOutlined style={{ color: "white", fontSize: "150%" }} />
                <Divider type="vertical" style={{ backgroundColor: 'white', fontSize: "170%" }} /> {/* Vertical line */}
                <ShareAltOutlined style={{ color: "white", fontSize: "150%" }} />
            </Flex>
        </div>
    );
}

import React, { useState } from 'react';
import FixedButtonGroup from './FixedButtonGroup';
import { Modal } from 'antd';


const WifiModal = ({ wifiVisible, setWifiVisible, wifiPass }) => {

    return (
        <Modal
            open={wifiVisible}
            onOk={() => setWifiVisible(false)}
            onCancel={() => setWifiVisible(false)}
            zIndex={2000}
            footer={null}
        >
            <div style={{ flex: 1 }}>
                <h3 style={{ margin: 0 }}>Wi-Fi Şifre: {wifiPass}</h3>

            </div>
        </Modal >
    );
};

export default WifiModal;

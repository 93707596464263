import React from 'react'
import { Result } from 'antd';



const EkranIcinTarihFormatla = (isoString) => {
    const date = new Date(isoString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Aylar 0'dan başlar
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const EkranIcinParaFormatla = (number) => {
    const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);

    return formattedNumber
};
function Yetki() {
    return (
        <Result
            status="403"
            // icon={<SmileOutlined />}
            title="Yetkisiz Giriş"
            subTitle="Bu Sayfaya Erişim Yetkiniz Bulunmuyor!"
            extra={null}
        />
    )
}

function Bekleyiniz() {
    return (
        <div>
            <div className="fs-2 d-flex justify-content-center align-items-center">
                Lütfen Bekleyiniz...
            </div>
            <div className='d-flex justify-content-center align-items-center'>

            </div>
        </div>
    )
}







export const Fonksiyon = {
    EkranIcinTarihFormatla,
    EkranIcinParaFormatla,
    Yetki,
    Bekleyiniz,



};